import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRippleEffect, IonRow, IonToolbar } from '@ionic/react'
import './modals.css'
import { useFilesContext } from '../context/Files.context'
import { useEffect, useState } from 'react'
import { FilesData } from '../interfaces/Files.interface'
import { chevronForward, close, cloudUploadOutline } from 'ionicons/icons'
import { useAuthContext } from '../context/Auth.context'
import { uploadFile } from '../routes/ia.routes'
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CloudUpload, Trash, UploadIcon } from '../icons'
import { LinearProgress } from '@mui/material'

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  

const SelectFileModal = ({open, handleClose}:{open: boolean, handleClose: () => void}) => {
    const {user} = useAuthContext()
    const {files, setFileSelected, getFiles} = useFilesContext()
    const [filesLoaded, setFilesLoaded] = useState<string[]>([])
    const [filesToLoad, setFilesToLoad] = useState<any[]>([])
    const [filesUploaded, setFilesUploaded] = useState(0)
    const [fileInProgress, setFileInProgress] = useState('')
    const [section, setSection] = useState<string>('')
    const [progressUpload, setProgressUpload] = useState(0)
    const [uploading, setUploading] = useState(false)


    useEffect(() => {
        console.log(filesLoaded)
    }, [filesLoaded])

    const selectFile = (file: FilesData) => {
        setFileSelected(file)
        handleClose()
    }

    const getFile = () => {
        const input = document.getElementById('file-uploader')
        if (input) {
            input.click()
        }
    }

    const progress = (e: any, nameFile: string, idFile: number) => {
        console.log(e.progress * 100)
        setProgressUpload(e.progress * 100)
        console.log(e.loaded === e.total)
        console.log((e.loaded === e.total) ? true : false)
        
    }

    const getFilesToSendServer = async (e: any) => {
        setUploading(false)
        console.log(e.target.files)
        const files : any[] = Array.from(e.target.files)
        setFilesToLoad(files)
        setFilesLoaded([])
        setFileInProgress('')
    }

    const uploadFiles = () => {
        if (filesToLoad.length > 0) {
            setUploading(true)
            console.log(files)
            const fileList: string[] = []
            sendFileOneByOne(filesToLoad, 0, fileList)
        } else {
            alert('No hay dcumentos seleccionados.')
        }
    }

    const sendFileOneByOne = async (files: any[], index: number, fileList: string[]) => {
        if (!files[index]) {
            alert('Archivos cargados')
        } else {
            setFilesUploaded(index + 1)
            const file = files[index]
            setFileInProgress(file.name)
            await uploadFile(file, user!._id, Date.now(), progress, section)
            setProgressUpload(100)
            setTimeout(() => {
                fileList.push(file.name)
                setFilesLoaded(fileList)
                setProgressUpload(0)
                setTimeout(() => {
                    sendFileOneByOne(files, index + 1, fileList)
                }, 1000)
            }, 1000);
        }
    }


    return (
        <IonModal
            isOpen={open}
            onWillDismiss={() => {handleClose()}}
            backdropDismiss={true}
            showBackdrop={true}
            className='file-selected-modal'
        >
            <IonToolbar>
                <IonButtons slot='end'>
                    <IonButton onClick={() => {handleClose()}}>
                        <IonIcon icon={close} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className='modalContainer' style={{position: 'relative'}}>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <p style={{fontWeight: 'bold', fontSize: 22}}>
                        Cargar documento 
                    </p>
                    <div 
                        onClick={() => {
                            const input = document.getElementById('uploadFile')
                            if (input) {
                                input.click()
                            }
                        }}
                        style={{margin: '0px 47.5px', padding: '30px 10px 10px 10px', backgroundColor: '#F8F8FF', borderRadius: 10, cursor: 'pointer'}}>
                        <img src={CloudUpload} height={54}/>
                        <p style={{fontWeight: 'bold', fontSize: 14}}>
                            {/* Arrastre y suelte documentos o */} <b style={{color: '#0A7ABF'}}>Busque documentos</b>
                        </p>
                    </div>
                    <input multiple onChange={getFilesToSendServer} id='uploadFile' type="file" accept='application/pdf' style={{display: 'none'}}/>
                </div>
                <div style={{width: '100%', textAlign: 'left', padding: '0px 30px'}}>
                    {
                        !uploading
                        ?
                        <p style={{fontWeight: 'bold', fontSize: 14, fontFamily: 'Poppins'}}>{filesToLoad.length > 0 ? `${filesToLoad.length} archivos seleccionados.` : 'Seleccione archivos para cargar'}</p>
                        :
                        <p style={{fontWeight: 'bold', fontSize: 14, fontFamily: 'Poppins'}}>Subiendo - {filesUploaded} / {filesToLoad.length} archivos</p>
                    }
                    <p>{fileInProgress}</p>
                    <IonGrid>
                        <IonRow>
                            <IonCol size='11'>
                                <LinearProgress variant="determinate" value={progressUpload} />
                            </IonCol>
                            <IonCol size='1'>
                                <p style={{fontSize: 10, margin: 0, marginLeft: 10, color: '#ccc'}}>{Math.ceil(progressUpload)}%</p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
                <div style={{width: '100%', textAlign: 'left', padding: '0px 30px'}}>
                    <p style={{fontWeight: 'bold', fontSize: 14, fontFamily: 'Poppins'}}>Cargados: </p>
                </div>
                <div style={{width: '100%', maxHeight: '20%', overflowY: 'auto', padding: '0px 30px'}}>
                    {
                        filesLoaded.map((fileName, index) => {
                            return (
                                <div key={index} style={{marginTop: 10, maxHeight: 36, border: '1px solid #11AF22', width: '100%', position: 'relative', padding: 5, marginBottom: 10}}>
                                    <p style={{fontWeight: 'bold', fontSize: 11, fontFamily: 'Poppins', margin: 0}}>{index + 1}.- {fileName}</p>
                                    {/* <img src={Trash} height={16} style={{position: 'absolute', right: 30, top: 5}}/> */}
                                </div>
                            )
                        })
                    }
                    {/* <div style={{marginTop: 10, maxHeight: 36, border: '1px solid #11AF22', width: '100%', position: 'relative', padding: 5, marginBottom: 10}}>
                        <p style={{fontWeight: 'bold', fontSize: 11, fontFamily: 'Poppins', margin: 0}}>documento-2.pdf</p>
                        <img src={Trash} height={16} style={{position: 'absolute', right: 30, top: 5}}/>
                    </div>
                    <div style={{marginTop: 10, maxHeight: 36, border: '1px solid #11AF22', width: '100%', position: 'relative', padding: 5, marginBottom: 10}}>
                        <p style={{fontWeight: 'bold', fontSize: 11, fontFamily: 'Poppins', margin: 0}}>documento-3.pdf</p>
                        <img src={Trash} height={16} style={{position: 'absolute', right: 30, top: 5}}/>
                    </div> */}
                </div>
                <div style={{position: 'absolute', bottom: 30, left: 0, width: '100%', textAlign: 'center'}}>
                    <button disabled={filesToLoad.length === 0} onClick={uploadFiles} className="ion-activatable ripple-parent rectangle" style={{width: 200, height:48, marginLeft: 'auto', marginRight: 'auto', borderRadius: 10, backgroundColor: '#41507A', color: '#fff'}}>
                        Cargar
                        <IonRippleEffect></IonRippleEffect>
                    </button>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default SelectFileModal
