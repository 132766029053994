import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRippleEffect, IonRow, IonSkeletonText, IonToolbar } from '@ionic/react'
import './modals.css'
import { useFilesContext } from '../context/Files.context'
import { useEffect, useState } from 'react'
import { FilesData } from '../interfaces/Files.interface'
import { chevronForward, close, cloudUploadOutline } from 'ionicons/icons'
import { useAuthContext } from '../context/Auth.context'
import { uploadFile } from '../routes/ia.routes'
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { UploadIcon } from '../icons'
import SelectFileModal from './SelectFile.modal'
import { useUsersContext } from '../context/Users.context'
import { Pagination } from '@mui/material'

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  

const UsersManagementModal = ({open, handleClose}:{open: boolean, handleClose: () => void}) => {
    const {userId} = useAuthContext()
    const {users} = useUsersContext()
    /* const {files, setFileSelected, getFiles} = useFilesContext() */
    const [filesLoad, setFilesLoad] = useState<any[]>([])
    const [speciality, setSpeciality] = useState<string>()
    const [openSelectFileModal, setOpenSelectFileModal] = useState(false)

    const [usersGroup, setUsersGroup] = useState<any>()

    const [usersPerPage, setUsersPerPage] = useState(10)

    const [pageSelected, setPageSelected] = useState(1)

    const [usersCitiesGroup, setUsersCitiesGroup] = useState<any>()
    const [usersRolesGroup, setUsersRolesGroup] = useState<any>()

    const [usersToShow, setUsersToShow] = useState<any[]>([])


    useEffect(() => {
        if (users.length > 0) {
            setUsersToShow(users)
            const usersCitiesGroupCache: any = {}
            const usersRolesGroupCache: any = {}

            users.forEach((user) => {
                if (!usersCitiesGroupCache[user.city]) {
                    usersCitiesGroupCache[user.city] = []
                }
                usersCitiesGroupCache[user.city].push(user)
                if (user.roles && user.roles[0] && user.roles[0].name) {
                    if (!usersRolesGroupCache[user.roles[0].name]) {
                        usersRolesGroupCache[user.roles[0].name] = []
                    }
                    usersRolesGroupCache[user.roles[0].name].push(user)
                } else {
                    if (!usersRolesGroupCache['SIN INFORMACIÓN']) {
                        usersRolesGroupCache['SIN INFORMACIÓN'] = []
                    }
                    usersRolesGroupCache['SIN INFORMACIÓN'].push(user)
                }
            })
            setUsersRolesGroup(usersRolesGroupCache)
            setUsersCitiesGroup(usersCitiesGroupCache)
        }
    }, [users])

    useEffect(() => {
        if (usersToShow.length > 0) {
            const usersGroupCache: any = {}
            let page = 0
            usersToShow.forEach((user, index) => {
                if (!usersGroupCache[page]) {
                    usersGroupCache[page] = []
                }
                usersGroupCache[page].push(user)
                if ((index + 1) === ((page + 1) * usersPerPage)) {
                    page = page + 1
                }
            })
            setUsersGroup(usersGroupCache)
        }
    }, [usersToShow])

    const progress = (e: any, nameFile: string, idFile: number) => {
        console.log(e.progress * 100)
        console.log(e.loaded === e.total)
        console.log((e.loaded === e.total) ? true : false)
        /* const isLoaded = (e.loaded === e.total) ? true : false */
        /* const filesLoadTemp = [...filesLoad]
        const fileIndex = filesLoadTemp.findIndex((file) => {
            (file.name === nameFile && idFile === file.id)
        })
        console.log(fileIndex)
        if (e.progress ) {
            filesLoadTemp[fileIndex].progress = (e.progress * 100)
            setFilesLoad(filesLoadTemp)
        }

        if (isLoaded) {
            const filesLoadCache = filesLoad.filter(file => {
                return (idFile !== file.id)
            })
            setFilesLoad(filesLoadCache)
        } */
        
    }



    return (
        <IonModal
            isOpen={open}
            onWillDismiss={() => {handleClose()}}
            backdropDismiss={false}
            className='users-management-modal'
        >
            <IonContent class='ion-padding' className='modalContainer' style={{position: 'relative'}}>
                <IonToolbar style={{borderBottom: '3px solid #5E6F9D'}}>
                    {<div style={{paddingLeft: 15}}>
                        <h3 style={{fontFamily: 'Poppins'}}>Usuarios</h3>
                        
                    </div>}
                    <IonButtons slot='end'>
                        <IonButton onClick={() => {handleClose()}}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <div style={{width: '100%', textAlign: 'right', padding: 20}}>
                    {usersCitiesGroup && 
                    <select
                        onChange={(e) => {setPageSelected(1); setTimeout(() => {
                            setUsersToShow(e.target.value === 'all' ? users : usersCitiesGroup[e.target.value])
                        }, 500);}}
                        placeholder='Ciudad' style={{marginLeft: 10, border: '1px solid #c4c4c4', fontSize: 12, borderRadius: 5, width: 120, height: 32}}>
                        <option value={'all'}>
                            CIUDADES
                        </option>
                        {
                            Object.keys(usersCitiesGroup).sort((a, b) => {
                                if (a < b) {
                                    return -1
                                }
                                if (a > b) {
                                    return 1
                                }
                                return 0
                            }).map((key, index) => {
                                return(
                                    <option key={index} value={key}>
                                        {key === 'undefined' ? 'SIN INFORMACIÓN' : key.toUpperCase()}
                                    </option>
                                )
                            })
                        }
                    </select>}
                    {usersRolesGroup && 
                    <select
                        onChange={(e) => {setPageSelected(1); setTimeout(() => {
                            setUsersToShow(e.target.value === 'all' ? users : usersRolesGroup[e.target.value])
                        }, 500);}}
                        placeholder='Ciudad' style={{marginLeft: 10, border: '1px solid #c4c4c4', fontSize: 12, borderRadius: 5, width: 120, height: 32}}>
                        <option value={'all'}>
                            TIPO USUARIO
                        </option>
                        {
                            Object.keys(usersRolesGroup).sort((a, b) => {
                                if (a < b) {
                                    return -1
                                }
                                if (a > b) {
                                    return 1
                                }
                                return 0
                            }).map((key, index) => {
                                return(
                                    <option key={index} value={key}>
                                        {/* key === 'undefined' ? 'SIN INFORMACIÓN' :  */key.toUpperCase()}
                                    </option>
                                )
                            })
                        }
                    </select>}
                </div>
                <div style={{width: '100%', textAlign: 'left', padding: 20}}>
                    <IonGrid>
                        <IonRow style={{borderBottom: '1px solid #ccc', marginRight: 20}}>
                            <IonCol size='3'>
                                <p>Nombre</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Ciudad</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Tipo Usuario</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Especialidad</p>
                            </IonCol>
                            <IonCol size='1' style={{textAlign: 'center'}}>
                                <p>Estado</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Acciones</p>
                            </IonCol>
                        </IonRow>
                        <div style={{
                            maxHeight: '50vh',
                            overflowY: 'auto',
                            fontSize: 14
                        }}>
                        {
                            usersGroup && usersGroup[pageSelected - 1].map((user: any, i: number) => {
                                return (
                                    <IonRow key={`${user.name}_${i}`}>
                                        <IonCol size='3'>
                                            <p>{user.name} {user.lastName}</p>
                                        </IonCol>
                                        <IonCol size='2'>
                                            <p>{user.city}</p>
                                        </IonCol>
                                        <IonCol size='2'>
                                            <p>{user.roles && user.roles[0] && user.roles[0].name}</p>
                                        </IonCol>
                                        <IonCol size='2'>
                                            <p>{user.section && user.section[0] && user.section[0].name}</p>
                                        </IonCol>
                                        <IonCol size='1'>
                                            <div style={user.state ? 
                                                {margin: '16px 10px', textAlign: 'center', border: '1px solid green', borderRadius: 3, color: 'green'} : 
                                                {margin: '16px 10px', textAlign: 'center', borderRadius: 3, backgroundColor: '#ccc', color: 'grey'}}>
                                                <p style={{margin: 0}}>{user.state ? 'ACTIVA' : 'INACTIVA'}</p>
                                            </div>
                                        </IonCol>
                                        <IonCol size='2'>
                                            
                                        </IonCol>

                                    </IonRow>
                                )
                            })
                        }
                        {
                            !usersGroup && 
                            <div style={{width: '100%'}}>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                            </div>
                        }
                        </div>
                    </IonGrid>
                </div>
                <div style={{position: 'absolute', bottom: 30, left: 0, width: '100%', textAlign: 'right', paddingRight: 30}}>
                {usersGroup && <Pagination
                    id={'pagination-user'}
                    onChange={(e, page) => {setPageSelected(page)}}
                    page={pageSelected}
                    defaultPage={1}
                    style={{position: 'absolute', right: 20, bottom: 60}}
                    count={Object.keys(usersGroup).length}
                    color="primary" />}
                    <button onClick={handleClose} className="ion-activatable ripple-parent rectangle" style={{width: 120, height:48, marginLeft: 'auto', marginRight: 10, borderRadius: 10, backgroundColor: '#D4D5D9', color: '#fff'}}>
                        Salir
                    </button>
                    {/* <button disabled className="ion-activatable ripple-parent rectangle" style={{width: 180, height:48, marginLeft: 'auto', marginRight: 'auto', borderRadius: 10, backgroundColor: '#41507A', color: '#fff'}}>
                        + Crear Nuevo Usuario
                    </button> */}
                </div>
            </IonContent>
        </IonModal>
    )
}

export default UsersManagementModal
